const theme = {
  breakpoints: ["40em", "52em", "70em"],
  colors: {
    text: "#131E26",
    background: "#ffffff",
    lightBackground: "#fafafa",
    primary: "#004999",
    secondary: "#51668A",
    tertiary: "#BCBE00",
    light: "#ffffff",
    dark: "#131E26",
    lightGrey: "#999999",
    backgroundCard: "#EEF0F3",
    lightGreyBackground: "#51668A20",
  },
  fonts: {
    body: '"Euclid Circular", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      '"Euclid Circular", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  space: [0, 4, 8, 16, 32, 48, 64, 96, 128, 192, 256, 512],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 72, 96, 128],
  fontWeights: {
    body: 400,
    heading: "normal",
  },
  lineHeights: {
    body: 1.5,
    heading: 1,
  },
  sizes: {
    container: 1280,
  },
  radii: {
    none: "0",
    xs: ".25rem",
    sm: ".5rem",
    md: "1rem",
    lg: "2rem",
    full: "9999px",
  },
  shadows: {
    none: "none",
    default:
      "0 20px 40px -10px rgba(50,50,93,0.15),0 10px 30px -20px rgba(0,0,0,0.15)",
  },

  text: {
    default: {
      lineHeight: "body",
      color: "secondary",
    },
    heading: {
      fontSize: [4],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
    },
    h1: {
      fontSize: [5, 6, 6, 7],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
    },
    h2: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: 1.3,
      fontWeight: "heading",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h3: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
    },
    h4: {
      fontSize: 4,
      fontWeight: "bold",
      color: "primary",
      mb: 2,
      mt: 4,
    },
    h5: {
      fontSize: 4,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    h6: {
      fontSize: 2,
      color: "primary",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    caption: {
      fontSize: 0,
      color: "lightGrey",
      fontWeight: "bold",
    },
    article: {
      fontSize: 3,
      lineHeight: 1.5,
    },
    kicker: {
      fontSize: 2,
      textTransform: "uppercase",
      mb: [2],
    },
  },
  layout: {
    container: {
      padding: [3, 4],
    },
    centered: {
      minWidth: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      padding: [3, 4],
    },
    sm: {
      maxWidth: "720px",
      padding: [3, 4],
    },
    md: {
      maxWidth: ["100%", "100%", "62%", "62%"],
      padding: [5, 5, 5],
    },
    fw: {
      maxWidth: "100%",
      padding: [5, 5, 5],
    },
    header: {
      maxWidth: "100%",
    },
  },
  forms: {
    input: {
      fontFamily: "body",
    },
  },
  buttons: {
    primary: {
      fontFamily: "body",
      cursor: "pointer",
      color: "light",
      bg: "primary",
      "&:hover": {
        bg: "primary",
        color: "white",
      },
    },
    secondary: {
      color: "background",
      bg: "secondary",
    },
  },
  links: {
    nav: {
      paddingX: 3,
      paddingY: 3,
      backgroundColor: "primary",
      "&.active": {
        color: "primary",
      },
    },
    tab: {
      textDecoration: "none",
      mr: 3,
      color: "text",
      "&.active": {
        color: "primary",
        fontWeight: "bold",
      },
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      a: {
        color: "primary",
        textDecoration: "none",
        "&:hover": {
          textDecoration: "none",
        },
        p: {
          color: "secondary",
        },
      },
      ".grecaptcha-badge": { visibility: "hidden" },
      // ".swiper-pagination": {
      //   position: "absolute",
      //   bottom: "10px",
      //   left: "10px",
      //   display: "flex",
      //   flexDirection: "column",
      //   justifyContent: "center",
      //   gap: "10px",
      // },
      "--swiper-theme-color": "#001489",
      "--swiper-navigation-sides-offset": "48px",
      // "--swiper-navigation-top-offset": "150px",
      ".swiper-container": { pb: 5 },
      // ".swiper-pagination-bullet": {
      //   width: "20px",
      //   height: "20px",
      //   borderColor: "primary",
      //   color: "white",
      // },

      // ".swiper-pagination-bullet-active": {
      //   border: "5px solid #001489",
      //   color: "white",
      // },
      // ".swiper-pagination-bullet-hover": {
      //   color: "#51668A",
      // },
    },
  },
}

export default theme
